@import "../../global.scss";

.portfolioList{
    font-size: 15px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile{
           margin: 20px;  
    }

    &.active{
        background-color: $mainColor;
        color: white;
    }
}